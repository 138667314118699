





























import Vue from 'vue'
import request from '@/utils/request'
import config from '@/utils/config'
interface DataTemp {
  areaData: any[],
  officeData: any[],
  formData: {
    name: string,
    areaId: string[],
    parentId: string,
  },
  rules: any,
}
export default Vue.extend({
  data(): DataTemp {
    return {
      areaData: [],
      officeData: [],
      formData: {
        name: '',
        areaId: [],
        parentId: '',
      },
      rules: {
        name: [{ required: true, message: '单位名称不能为空', trigger: 'blur' }],
        areaId: [{ required: true, type: 'array', message: '区域不能为空', trigger: 'change' }],
        parentId: [{ required: true, message: '上级不能为空', trigger: 'change' }],
      },
    }
  },
  mounted() {
    const root: any = {
      value: config.defaultStationObj.adminCode,
      label: config.defaultStationObj.stationName,
      expand: false,
      loading: false,
      children: [],
    }
    this.loadAreaData(root)
    this.areaData.push(root)
    request.get(`/api/sys/office/operable_list`, null)
    .then((data) => {
      if (data) {
        this.officeData = data
      }
    })
  },
  methods: {
    loadAreaData(item: any) {
      const { value } = item
      request.get(`/api/sys/area/children/${value}`, null)
      .then((data) => {
        if (data) {
          item.children = this.buildTreeNode(data)
        }
      })
    },
    buildTreeNode(data: any) {
      if (!data || data.size === 0) {
        return []
      }
      const array: any[] = []
      data.forEach((e: any) => array.push(this.buildNode(e)))
      return array
    },
    buildNode(geoData: any) {
      const { id: value, name: label } = geoData
      const node = {value, label}
      return node
    },
    submit() {
      const {name, areaId, parentId} = this.formData
      const dataForm: any = this.$refs.dataForm
      dataForm.validate((valid: boolean) => {
        if (valid) {
          request.post('/api/sys/office', {name, areaId: areaId[areaId.length - 1], parentId})
          .then((res) => {
            this.$Message.success('保存成功!')
            this.$router.replace('/sys_manage/office')
          })
        }
      })
    },
  },
})
